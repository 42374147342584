import React from "react";

import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";

import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const renderFavoriteIcon = (isFavorite: boolean) => {
  const props = {
    "data-testid": "favorite-icon",
    className: `h-4 w-4 ${isFavorite ? "text-primary" : "text-foreground"}`,
  };

  const Icon = isFavorite ? StarIcon : StarIconOutline;

  return <Icon {...props} />;
};
