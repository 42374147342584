import { PropsWithChildren, ReactNode, useCallback, useMemo } from "react";

import { ClearFiltersButton } from "./ClearFiltersButton";
import { ExpandableContent } from "./ExpandableContent";
import FilterableTableContext, { FilterOption } from "./FilterableTableContext";
import { RowContext, RowContextProvider } from "./RowContext";
import { SearchInput } from "./SearchInput";
import { TableContent } from "./TableContent";
import { TableFilterDropdown } from "./TableFilterDropdown";
import { TableSortLabel } from "./TableSortLabel";
import { TopBar } from "./TopBar";

export interface FilterMap {
  [key: string]: string[];
}

interface FilterableTableProps extends PropsWithChildren {
  onFilter?: (filters: FilterMap) => void;
  onSort?: (sort: string) => void;
  filterValues?: FilterMap;
  sortValues?: string[];
}

type FilterableTableType = ((props: FilterableTableProps) => ReactNode) & {
  TopBar: typeof TopBar;
  SearchInput: typeof SearchInput;
  Filter: typeof TableFilterDropdown;
  ClearFiltersButton: typeof ClearFiltersButton;
  TableContent: typeof TableContent;
  ExpandableContent: typeof ExpandableContent;
  TableSortLabel: typeof TableSortLabel;
};

export interface Filter {
  accessorKey: string;
  // How the filter will be displayed in the UI.
  label: string;
  // The type of the filter. Can be 'text', 'select', 'date', 'number', 'boolean'.
  type: FilterType;
  // If the filter can only contain certain options, this will be the array of options.
  options?: FilterOption[];
  // The icon to be displayed in the UI.
  icon?: ReactNode;
  // If the filter can only have a single value
  singleValue?: boolean;
  disabled?: boolean;
  placeholder?: string;
  hideClearOption?: boolean;
}

export const FilterableTable: FilterableTableType = ({
  children,
  filterValues,
  onFilter,
  sortValues,
  onSort,
}: FilterableTableProps) => {
  const removeFilterValue = (
    currentFilterValues: string[],
    option: FilterOption
  ) => {
    return currentFilterValues.filter((value) => value !== option.value);
  };

  const toggleFilterOption = useCallback(
    (filter: Filter, option: FilterOption) => {
      const { singleValue } = filter;
      const currentFilterValues = filterValues?.[filter.accessorKey] ?? [];

      if (singleValue) {
        onFilter?.({
          ...filterValues,
          [filter.accessorKey]: [option.value],
        });
        return;
      }

      const newFilterValues = currentFilterValues.includes(option.value)
        ? removeFilterValue(currentFilterValues, option)
        : [...currentFilterValues, option.value];

      onFilter?.({
        ...filterValues,
        [filter.accessorKey]: newFilterValues,
      });
    },
    [filterValues, onFilter]
  );

  const isFilterOptionSelected = useCallback(
    (filter: Filter, option: FilterOption) =>
      filterValues?.[filter.accessorKey]?.includes(option.value) ?? false,
    [filterValues]
  );

  const clearFilterValues = useCallback(
    (filter: Filter) => {
      onFilter?.({
        ...filterValues,
        [filter.accessorKey]: [],
      });
    },
    [filterValues, onFilter]
  );

  const contextValue = useMemo(
    () => ({
      // Actions
      toggleFilterOption,
      isFilterOptionSelected,
      clearFilterValues,
      sort: onSort ?? (() => {}),
      filter: onFilter ?? (() => {}),

      // Values
      filterValues: filterValues ?? {},
      sortValues: sortValues ?? [],
    }),
    [toggleFilterOption, isFilterOptionSelected, clearFilterValues, sortValues]
  );

  return (
    <FilterableTableContext.Provider value={contextValue}>
      <div className={"flex w-full flex-col gap-[24px] min-h-full flex-1"}>
        {children}
      </div>
    </FilterableTableContext.Provider>
  );
};

FilterableTable.TopBar = TopBar;
FilterableTable.SearchInput = SearchInput;
FilterableTable.Filter = TableFilterDropdown;
FilterableTable.ClearFiltersButton = ClearFiltersButton;
FilterableTable.TableContent = TableContent;
FilterableTable.ExpandableContent = ExpandableContent;
FilterableTable.TableSortLabel = TableSortLabel;

export type FilterType = "text" | "select" | "date" | "number" | "boolean";

export { RowContext, RowContextProvider };
