import { KeyValuePair } from "./keyvaluepairs";

export enum DocumentOldStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum DocumentStatus {
  pending = "pending",
  docxToPdfInProgress = "docx_to_pdf_in_progress",
  docxToPdfFailed = "docx_to_pdf_failed",
  docxToPdfCompleted = "docx_to_pdf_completed",
  preprocessInProgress = "preprocess_in_progress",
  preprocessFailed = "preprocess_failed",
  preprocessCompleted = "preprocess_completed",
  kvpInProgress = "kvp_in_progress",
  kvpFailed = "kvp_failed",
  kvpCompleted = "kvp_completed",
  extraKvpInProgress = "extra_kvp_in_progress",
  extraKvpFailed = "extra_kvp_failed",
  extraKvpCompleted = "extra_kvp_completed",
  completed = "completed",
}

export enum FeedbackResult {
  POSITIVE = "positive",
  NEGATIVE = "negative",
}

export interface DocumentType {
  id: string;
  name: string;
  keyValuePairs?: KeyValuePair[];

  // Internal use only
  _original?: DocumentType;
  count?: number;
}

export interface DocumentTypeWithCount {
  id: string;
  name: string;
  count: number;
}

export interface DocumentKeyValuePair {
  id: string;
  documentId?: string;
  name: string;
  value: string;
  isFavorite: boolean;
  isDefault: boolean;
  originalValue?: string;
  dateValue?: string;
  feedback?: FeedbackResult;
  keyValuePair?: KeyValuePair;
}

export interface DocumentTag {
  id: string;
  name: string;
  document?: Document;
}

export interface DocumentParty {
  name: string;
}

export interface Document {
  userId: string;
  documentTypeId: string;
  createdAt: string;
  id: string;
  name: string;
  type: string;
  ext: string | null;
  updatedAt: string;
  url: string;
  status: DocumentStatus;
  markdownJsonUrl?: string;
  kvps?: DocumentKeyValuePair[];
  parties: string[];
  tags: { name: string }[];
  isNewUpload: boolean;
  expirationDate: string;
  insights?: { id: string; name?: string }[];
  summarization?: DocumentSummarization;
  reprocessCount: number;

  // internal use only
  _isInvalid?: boolean;
  _invalidReason?: string;
  _isTemporaryFile?: boolean;

  // Used in the admin app
  _original?: Document;
}

export interface MarkdownSection {
  text: string;
  content: string[];
}

export interface Section {
  childDocumentKey: string;
  createdAt?: string;
  id?: string;
  masterDocumentKey: string;
  status?: DocumentOldStatus | null;
  updatedAt?: string;
  comparisonResultUrl?: string;
}

export enum DocumentSummarizationStatus {
  IN_PROGRESS = "IN_PROGRESS",
  FAILED = "FAILED",
}

export interface DocumentSummarization {
  id: string;
  result?: string;
  status: string;
  document?: Document;
}

export enum DocumentTab {
  EXPIRED = "expired",
  ACTIVE = "active",
}
