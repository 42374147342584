/* eslint-disable @typescript-eslint/no-explicit-any */
import { Document, DocumentStatus, PaginatedResponse, PaginationParams } from "@atlas-ui/types";

import { isEmpty } from "lodash";

import API from "../api";
import { generateSHA256Checksum } from "../utils";

interface PatchDocumentParams extends Partial<Document> {
  id: string;
  documentUploaded?: boolean;
}

interface PostDocumentsParams {
  files: FileWithExtension[];
}

interface GetDocumentsParams extends PaginationParams {
  documentTypeId?: string | string[] | null;
  parties?: string[];
  tags?: string[];
}

interface GetDocumentByIdExtraParams {
  unmarkAsNew?: boolean;
}

type GetDocumentById = (id: string, params?: GetDocumentByIdExtraParams) => Promise<Document>;
type PatchDocument = (params: PatchDocumentParams) => Promise<Document>;
type PostDocuments = (params: PostDocumentsParams) => Promise<Document>;

export type FileWithExtension = {
  file: File;
  ext: string;
};

// Documents helpers
const getPresignedUrl = async (format: string, checksum: string) => {
  try {
    const response = await API.post("/documents", { ext: format, checksum });
    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting presigned url");
  }
};

// Documents endpoints
export const getDocuments = async (options?: GetDocumentsParams) => {
  try {
    let docTypes = options?.documentTypeId;

    if (docTypes && Array.isArray(docTypes)) {
      docTypes = docTypes.join(",");
    }

    const response = await API.get("/documents", {
      params: {
        ...options,
        documentTypeId: isEmpty(docTypes) ? undefined : docTypes,
        tags: isEmpty(options?.tags) ? undefined : options?.tags?.join(","),
        parties: isEmpty(options?.parties)
          ? undefined
          : options?.parties?.map((party) => `"${party}"`).join(","),
      },
    });
    return response.data as PaginatedResponse<Document>;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting documents");
  }
};

export const getInProgressCount = async () => {
  try {
    const response = await API.get("/documents/inprogress-count");
    return response.data?.count;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting count");
  }
};

export const getDocumentsByTab = async (
  tab: "active" | "expired",
  options?: GetDocumentsParams
) => {
  try {
    let docTypes = options?.documentTypeId;

    if (docTypes && Array.isArray(docTypes)) {
      docTypes = docTypes.join(",");
    }

    const response = await API.get("/documents-extra/list", {
      params: {
        ...options,
        documentTypeId: isEmpty(docTypes) ? undefined : docTypes,
        tags: isEmpty(options?.tags) ? undefined : options?.tags?.join(","),
        parties: isEmpty(options?.parties)
          ? undefined
          : options?.parties?.join(","),
        tab,
      },
    });
    return response.data as PaginatedResponse<Document>;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting documents");
  }
};

export const getDocumentById: GetDocumentById = async (id, params: GetDocumentByIdExtraParams = {}) => {
  try {
    const response = await API.get(`/documents/${id}`, {
      params: {
        ...params,
      },
    });

    const data = response.data;
    const formattedData = {
      ...data,
    };

    return formattedData as Document;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting document");
  }
};

export const patchDocument: PatchDocument = async ({ id, ...rest }) => {
  try {
    const response = await API.patch(`/documents/${id}`, {
      id,
      ...rest,
    });

    return response.data;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error patching document");
  }
};

export const postDocument: PostDocuments = async ({ files }) => {
  const { file, ext } = files[0];

  try {
    const checksum = await generateSHA256Checksum(file);
    const { id, presignedPost } = await getPresignedUrl(ext, checksum);

    const payload = new FormData();
    for (const key of Object.keys(presignedPost.fields)) {
      payload.append(key, presignedPost.fields[key]);
    }
    payload.append("x-amz-checksum-algorithm", "SHA256");
    payload.append("x-amz-checksum-sha256", checksum);
    payload.append("file", file);

    const response = await fetch(presignedPost.url, {
      method: "POST",
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    return await patchDocument({
      id: id as string,
      name: file.name,
      documentUploaded: true,
    });
  } catch (error) {
    throw new Error((error as Error)?.message || "Error uploading document");
  }
};

export const deleteDocument = async (id: string) => {
  try {
    const response = await API.delete(`/documents/${id}`);
    return response.data;
  } catch (error) {
    if ((error as any)?.response?.data.data) {
      throw (error as any)?.response?.data.data;
    }

    throw new Error((error as Error)?.message || "Error deleting document");
  }
};

export const deleteDocumentsBatch = async (ids: string[]) => {
  try {
    return await Promise.all(ids.map((id) => deleteDocument(id)));
  } catch (error) {
    throw new Error((error as Error)?.message || "Error deleting documents");
  }
};

interface GetExpiringDocumentsParams {
  documentTypeId?: string | null;
  tags?: string[];
  parties?: string[];
  search?: string;
}

export const getExpiringDocuments = async ({
  documentTypeId,
  tags,
  parties,
  search,
}: GetExpiringDocumentsParams = {}) => {
  try {
    const { data } = await API.get("/documents-extra/expiring", {
      params: {
        documentTypeId: documentTypeId === "all" ? undefined : documentTypeId,
        tags: !tags || isEmpty(tags) ? undefined : tags.join(","),
        parties:
          !parties || isEmpty(parties)
            ? undefined
            : parties.map((p) => `"${p}"`).join(","),
        search: !search || isEmpty(search) ? undefined : search,
      },
    });

    return data as Document[];
  } catch (err) {
    throw new Error(
      (err as Error)?.message || "Error getting expiring documents"
    );
  }
};

export const createTempDocument = (file: File) => ({
  id: `__TEMP__${file.name}`,
  userId: "",
  createdAt: new Date().toISOString(),
  status: DocumentStatus.extraKvpInProgress,
  name: file.name,
  parties: [],
  type: file.type,
  updatedAt: new Date().toISOString(),
  url: URL.createObjectURL(file),
  markdownJsonUrl: "",
  ext: file.name.split(".").pop() ?? null,
  isTemporaryFile: true,
  tags: [],
  isNewUpload: false,
  expirationDate: "",
});

export const getDocumentsInProgress = async (): Promise<Document[]> => {
  try {
    const response = await API.get("/documents-extra/in-progress");

    return response.data as Document[];
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting documents");
  }
}

export const dismissDocuments = async (ids: string[]) => {
  try {
    await API.put("/documents-extra/dismiss", { ids });
  } catch (error) {
    throw new Error((error as Error)?.message || "Error dismissing documents");
  }
}
