/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEventHandler, FC, FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';

import { ArrowsPointingOutIcon } from '@heroicons/react/20/solid';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  PrinterIcon
} from '@heroicons/react/24/outline';
import { FullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { PageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { PrintPlugin } from '@react-pdf-viewer/print';
import { PropertiesPlugin } from '@react-pdf-viewer/properties';
import { RenderSearchProps, SearchPlugin } from '@react-pdf-viewer/search';
import { ZoomPlugin } from '@react-pdf-viewer/zoom';

import { clamp, isEmpty } from 'lodash';

import {
  Button,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator
} from '../../atoms';

interface PdfViewerToolbarProps {
  plugins: {
    zoomPluginInstance: ZoomPlugin;
    searchPluginInstance: SearchPlugin;
    pageNavigationPluginInstance: PageNavigationPlugin;
    fullScreenPluginInstance: FullScreenPlugin;
    printPluginInstance: PrintPlugin;
    propertiesPluginInstance: PropertiesPlugin;
  };
}

const RenderSearch = ({
  keyword,
  currentMatch,
  setKeyword,
  jumpToNextMatch,
  search,
  clearKeyword,
}: RenderSearchProps) => {
  const [lastSearch, setLastSearch] = useState<string>("");

  const onChangeSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setKeyword(event.target.value);

    // If the input is empty, clear the search
    if (isEmpty(event.target.value)) {
      clearKeyword();
    }
  };

  const onKeydownSearch: KeyboardEventHandler = (event) => {
    if (event.key === "Enter") {
      if (
        keyword &&
        !isEmpty(keyword) &&
        currentMatch &&
        lastSearch === keyword
      ) {
        jumpToNextMatch();
      } else {
        setLastSearch(keyword);
        search();
      }
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    // If the input is empty, clear the search
    if (isEmpty(event.target.value)) {
      clearKeyword();
    }
  };

  return (
    <Input
      startIcon={<MagnifyingGlassIcon className="h-4 w-4 text-foreground" />}
      placeholder="Search"
      onChange={onChangeSearch}
      onBlur={handleBlur}
      className="max-h-8"
      value={keyword}
      onKeyDown={onKeydownSearch}
    />
  );
};

export const PdfViewerToolbar: FC<PdfViewerToolbarProps> = ({ plugins }) => {
  const [currentZoom, setCurrentZoom] = useState<number>(1);

  const {
    zoomPluginInstance: { zoomTo },
    searchPluginInstance: { Search },
    pageNavigationPluginInstance: {
      CurrentPageInput,
      NumberOfPages,
      GoToNextPage,
      GoToPreviousPage,
    },
    printPluginInstance: { Print },
    fullScreenPluginInstance: { EnterFullScreen },
    propertiesPluginInstance: { ShowProperties },
  } = plugins;

  const changeZoom = (value: number, type?: "set" | "add") => {
    if (type === "set") {
      setCurrentZoom(clamp(value / 100, 0.1, 2));
      return;
    }

    setCurrentZoom((prev) => clamp(Number(prev) + value / 100, 0.1, 2));
  };

  useEffect(() => {
    // A timeout is needed because sometimes
    // the zoomTo method is uneffective due to the fact
    // that the zoom plugin is not loaded yet
    setTimeout(() => {
      zoomTo(currentZoom);
    }, 100);
  }, [currentZoom, zoomTo]);

  const zoomValues = Array.from<number>({ length: 20 }).fill(0);

  return (
    <div className="flex p-2 gap-2 items-center self-center">
      <Search>{RenderSearch}</Search>

      <div className="flex gap-2 justify-center items-center">
        <GoToPreviousPage>
          {({ onClick }) => (
            <Button size="md" variant="icon" onClick={onClick}>
              <ChevronUpIcon className="h-4 w-4 text-foreground" />
            </Button>
          )}
        </GoToPreviousPage>

        <div className="flex items-center min-h-[40px]">
          <div className={"w-[50px] flex"}><CurrentPageInput /></div><span>/</span><span><NumberOfPages /></span>
        </div>

        <GoToNextPage>
          {({ onClick }) => (
            <Button size="md" variant="icon" onClick={onClick}>
              <ChevronDownIcon className="h-4 w-4 text-foreground" />
            </Button>
          )}
        </GoToNextPage>
      </div>

      <div className="flex gap-2 items-center">
        <Separator className="h-6 mx-2" orientation="vertical" />
        <div className="flex gap-6 items-center">
          <ShowProperties>
            {({ onClick }) => (
              <Button size="md" variant="icon" onClick={onClick}>
                <InformationCircleIcon className="h-4 w-4" />
              </Button>
            )}
          </ShowProperties>
        </div>
        <Separator className="h-6 mx-2" orientation="vertical" />
      </div>

      <div className="flex gap-2 justify-center items-center">
        <Button size="md" variant="icon" onClick={() => changeZoom(-10)}>
          <MinusCircleIcon className="h-4 w-4 text-foreground" />
        </Button>

        <Select
          onValueChange={(value) => changeZoom(Number(value), "set")}
          value={(currentZoom * 100).toFixed(0)}
        >
          <SelectGroup>
            <SelectTrigger className="h-8">
              <SelectValue placeholder="Zoom" />
            </SelectTrigger>
            <SelectContent className="max-h-[20vh]">
              {zoomValues.map((_: number, index) => {
                const zoomVal = (index + 1) * 10;

                return (
                  <SelectItem key={zoomVal} value={zoomVal.toString()}>
                    {zoomVal}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </SelectGroup>
        </Select>

        <Button size="md" variant="icon" onClick={() => changeZoom(10)}>
          <PlusCircleIcon className="h-4 w-4 text-foreground" />
        </Button>
      </div>

      <div className="flex gap-2 items-center">
        <Separator className="h-6" orientation="vertical" />
        <EnterFullScreen>
          {({ onClick }) => (
            <Button size="md" variant="icon" onClick={onClick}>
              <ArrowsPointingOutIcon className="h-4 w-4 text-foreground" />
            </Button>
          )}
        </EnterFullScreen>

        <Print>
          {({ onClick }) => (
            <Button size="md" variant="icon" onClick={onClick}>
              <PrinterIcon className="h-4 w-4 text-foreground" />
            </Button>
          )}
        </Print>
      </div>
    </div>
  );
};
