import { HTMLAttributes, useState } from "react";

import { FeedbackResult } from "@atlas-ui/types";
import { HandThumbDownIcon, HandThumbUpIcon } from "@heroicons/react/24/solid";

import mixpanel from "mixpanel-browser";
import { twMerge } from "tailwind-merge";

import { cn, renderFavoriteIcon } from "../../../utils";
import {
  Button,
  Collapse,
  ExpandableParagraph,
  Separator,
  Spinner,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "../../atoms";

export interface KeyValuePairProps<T> extends HTMLAttributes<HTMLDivElement> {
  label: string;
  value: T;
  isFavorite?: boolean;
  isLoading?: boolean;
  isEditable?: boolean;
  disableFeedback?: boolean;
  disableDelete?: boolean;
  disableUpdate?: boolean;
  feedback?: FeedbackResult;
  icon?: React.ReactNode;
  code: string;
  documentType: string;
  formatter?: (value: T) => string;
  onDelete?: () => void;
  onUpdate?: () => void;
  onFavorite?: () => void;
  onVote?: (feedback: FeedbackResult) => void;
}

export const KeyValuePair = <T,>({
  label,
  value,
  className,
  isFavorite,
  isLoading,
  isEditable,
  disableFeedback,
  disableDelete,
  disableUpdate,
  feedback,
  icon,
  code,
  documentType,
  formatter,
  onDelete,
  onUpdate,
  onFavorite,
  onVote,
  ...props
}: KeyValuePairProps<T>) => {
  const [currentFeedback, setCurrentFeedback] = useState<
    FeedbackResult | undefined
  >(feedback);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);

  const onAddManually = () => {
    onUpdate?.();

    mixpanel.track("action-intention-manually-kvp", {
      kvp_name: label,
      kvp_code: code,
      document_type: documentType,
    });
  };

  const renderKeyValuePairValue = () => {
    if (isLoading) {
      return <Spinner size={4} data-testid="loading-spinner" />;
    }

    if (formatter) {
      return formatter(value);
    }

    if (value === "NOT_FOUND" || value === '"NOT_FOUND"') {
      return (
        <button
          data-testid="not-found"
          onClick={onAddManually}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onAddManually();
            }
          }}
          className="text-primary text-sm"
        >
          Add Manually
        </button>
      );
    }

    return String(value);
  };

  const handleFeedback = (feedback: FeedbackResult) => {
    onVote?.(feedback);
    setCurrentFeedback(feedback);
    setShowThankYou(true);

    setTimeout(() => {
      setShowThankYou(false);
    }, 2000);
  };

  const renderFeedbackSection = () => {
    if (feedback) {
      return <div />;
    }

    return (
      <>
        <div
          className={twMerge(
            "bg-background px-4 py-2 text-muted-foreground text-xs rounded-md opacity-0",
            showThankYou ? "animate-fade-in opacity-1" : "animate-fade-out",
            !currentFeedback && "hidden"
          )}
          data-testid="thank-you"
        >
          Thanks for your feedback.
        </div>
        {!currentFeedback && !disableFeedback ? (
          <div
            className="flex py-2 px-4 gap-4 items-center bg-white rounded-lg"
            data-testid="feedback"
          >
            <button
              onClick={() => handleFeedback(FeedbackResult.POSITIVE)}
              data-testid="positive"
            >
              <HandThumbUpIcon className="h-6 w-6 text-atlas-green" />
            </button>
            <Separator orientation="vertical" className="h-5" />
            <button
              onClick={() => handleFeedback(FeedbackResult.NEGATIVE)}
              data-testid="negative"
            >
              <HandThumbDownIcon className="h-6 w-6 text-destructive" />
            </button>
          </div>
        ) : (
          <div />
        )}
      </>
    );
  };

  const isEditDisabled = !currentFeedback && !disableFeedback;

  return (
    <div
      className={cn(
        "flex flex-col gap-1 px-4 pt-4 pb-2 relative min-h-[96px] bg-background hover:bg-accent rounded-lg",
        className
      )}
      data-testid="key-value-pair"
      {...props}
    >
      <div className="flex items-center justify-between gap-2">
        <label className="flex items-center gap-2 text-sm font-medium text-foreground">
          {icon} {label}
        </label>

        <div className="flex items-start h-full gap-2">
          {onFavorite && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div>
                    <Button variant="ghost" onClick={onFavorite} size="xs">
                      {renderFavoriteIcon(!!isFavorite)}
                    </Button>
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <TooltipArrow style={{ fill: "white" }} />
                  <span className="text-xs">
                    {isFavorite ? "Unfavorite" : "Favorite"}
                  </span>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-sm text-muted-foreground">
          <ExpandableParagraph className="whitespace-pre-wrap">
            {renderKeyValuePairValue()}
          </ExpandableParagraph>
        </span>
        <Collapse isOpen={!!isEditable} duration={0.3} enableOpacity>
          <div className="flex justify-between items-center">
            {renderFeedbackSection()}
            <div className="flex gap-2 items-center">
              {onUpdate && !disableUpdate && (
                <button
                  className="disabled:text-ring disabled:cursor-not-allowed text-primary text-sm cursor-pointer"
                  data-testid="edit"
                  aria-label="Edit"
                  disabled={isEditDisabled}
                  onClick={onUpdate}
                >
                  Edit
                </button>
              )}
              {onUpdate && !disableUpdate && onDelete && !disableDelete && (
                <Separator
                  className="h-5"
                  orientation="vertical"
                  data-testid="separator"
                />
              )}
              {onDelete && !disableDelete && (
                <button
                  className="disabled:text-ring disabled:cursor-not-allowed text-destructive text-sm cursor-pointer"
                  data-testid="delete"
                  aria-label="Delete"
                  disabled={!currentFeedback && !disableFeedback}
                  onClick={onDelete}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};
