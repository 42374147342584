import { User } from "./user";

export interface Organization {
  id: string;
  name: string;
  industry: string;
  users: User[];
  userId?: string;
}

export enum LimitType {
  DOCUMENTS_UPLOAD = 'DOCUMENTS_UPLOAD',
  DOCUMENTS_SUMMARIES = 'DOCUMENTS_SUMMARIES',
  INSIGHTS = 'INSIGHTS',
  REPROCESS_PER_DOCUMENT = 'REPROCESS_PER_DOCUMENT',
}

export interface Limit {
  type: LimitType,
  value: number;
}

export interface Tier {
  name: string,
  limits: Limit[];
}

export interface OrganizationLimits {
  tier: Tier | null;
  usage: Limit[];
}
