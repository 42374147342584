import * as React from "react";

import { Slot } from "@radix-ui/react-slot";

import { cva, type VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "ghost"
  | "link"
  | "destructive"
  | "icon";
export type ButtonSize = "xl" | "md" | "xs";

const buttonVariants = cva(
  "inline-flex items-center justify-center font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        primary:
          "bg-primary text-primary-foreground hover:bg-primary-hover disabled:bg-muted disabled:text-muted-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary-hover disabled:bg-muted disabled:text-muted-foreground",

        outline:
          "bg-transparent border text-foreground hover:bg-muted disabled:text-muted-foreground",
        ghost: "text-foreground hover:bg-muted disabled:text-muted-foreground",
        link: "text-foreground hover:text-primary disabled:text-foreground",
        icon: "border-border border fill-foreground text-foreground hover:bg-muted active:bg-primary active:border-transparent active:text-primary-foreground active:fill-primary-foreground",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive-hover disabled:bg-muted disabled:text-muted-foreground",
      },
      size: {
        xl: "px-4 py-2 rounded-lg text-base font-medium",
        md: "px-2 py-1 rounded-lg text-sm font-medium",
        xs: "px-1 py-1 rounded-md text-xs font-medium",
      },
    },
    compoundVariants: [
      {
        variant: "icon",
        size: "xl",
        className: "px-3 py-3",
      },
      {
        variant: "icon",
        size: "md",
        className: "px-2 py-2",
      },
      {
        variant: "icon",
        size: "xs",
        className: "px-1 py-1 border-none rounded-sm",
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "xl",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={twMerge(buttonVariants({ variant, size, className }))}
        ref={ref}
        type={props.type ?? "button"}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";

export { buttonVariants, Button };
