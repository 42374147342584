import React from "react";

export const AiIcon = ({ className = "", testId = "" }) => {
  return (
    <svg
      className={`fill-current ${className}`} // This applies the Tailwind classes passed through props
      width="16"
      height="16"
      viewBox="0 0 16 16"
      data-testid={testId}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ai-icon">
        <path
          id="Star 1"
          d="M6.8453 0L8.69415 4.85695L13.6906 6.65418L8.69415 8.45142L6.8453 13.3084L4.99644 8.45142L0 6.65418L4.99644 4.85695L6.8453 0Z"
        />
        <path
          id="Star 2"
          d="M12.555 9.3024L13.4854 11.7467L15.9999 12.6512L13.4854 13.5557L12.555 16L11.6245 13.5557L9.10999 12.6512L11.6245 11.7467L12.555 9.3024Z"
        />
      </g>
    </svg>
  );
};
