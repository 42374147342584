/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createElement,
  forwardRef,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';

import { cva } from 'class-variance-authority';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { Schema } from 'yup';

import { Button, DatePicker, Input, Spinner } from '../../atoms';
import { BaseDialog } from './BaseDialog';

export interface FormDialogToggle {
  name: string;
  icon?: FunctionComponent;
  onClick?: () => void;
  className?: string;
  enabledClassName?: string;
  disabledClassName?: string;
  value?: boolean;
}

export interface FormDialogValueShape {
  [key: string]: any;
}

export interface FormDialogToggleValues {
  [key: string]: boolean;
}

interface FormDialogOptions {
  discardLabel?: string;
  saveLabel?: string;
}

export interface FormSchema {
  [key: string]: {
    label: string;
    name: string;
    placeholder?: string;
    type?: "text" | "date";
  };
}

export type FormDialogVariant = "horizontal" | "vertical";

interface FormDialogProps {
  validationSchema?: Schema;
  schema?: FormSchema;
  toggles?: FormDialogToggle[];
  isOpen?: boolean;
  title: string;
  initialValues?: FormDialogValueShape;
  variant?: FormDialogVariant;
  onConfirm?: (values: any, toggles: FormDialogToggleValues) => Promise<void>;
  onDiscard?: () => void;
  options?: FormDialogOptions;
}

const defaultOptions = {
  discardLabel: "Discard",
  saveLabel: "Save Changes",
};

const fieldContainerVariant = cva("", {
  variants: {
    variant: {
      vertical: "flex flex-col gap-2",
      horizontal: "grid grid-cols-[1fr,8fr] gap-4",
    },
  },
});

const fieldVariant = cva("", {
  variants: {
    variant: {
      vertical: "flex flex-col gap-2",
      horizontal: "flex flex-row",
    },
  },
});

export interface FormDialogHandle {
  reset: () => void;
}

export const FormDialog = forwardRef<
  FormDialogHandle,
  PropsWithChildren<FormDialogProps>
>(
  (
    {
      isOpen,
      validationSchema,
      schema,
      initialValues,
      toggles,
      title,
      onConfirm,
      onDiscard,
      options,
      children,
      variant = "vertical",
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [togglesValues, setTogglesValues] = useState<FormDialogToggleValues>(
      {}
    );

    const onSubmit = useCallback(
      async (values: any) => {
        setIsLoading(true);

        try {
          await onConfirm?.(values, togglesValues);
        } finally {
          setIsLoading(false);
        }
      },
      [onConfirm, togglesValues]
    );

    const {
      handleBlur,
      handleChange,
      setFieldValue,
      handleSubmit,
      resetForm,
      isValid,
      values,
      errors,
    } = useFormik({
      onSubmit,
      initialValues: initialValues ?? {},
      validationSchema,
    });

    useImperativeHandle(ref, () => ({
      reset: () => {
        resetForm();
      },
    }));

    const actualOptions = {
      ...defaultOptions,
      ...options,
    };

    const getToggleValue = useCallback(
      (name: string) => {
        if (!toggles) return undefined;
        const toggle = toggles.find((toggle) => toggle.name === name);
        if (!toggle) return undefined;

        if (toggle.value !== undefined) {
          return toggle.value;
        }

        return togglesValues[name];
      },
      [toggles, togglesValues]
    );

    const toggleButtons = toggles?.map(
      ({
        name,
        onClick,
        icon,
        className,
        enabledClassName,
        disabledClassName,
      }) => (
        <Button
          variant="icon"
          data-testid={`toggle-btn-${name}`}
          data-value={getToggleValue(name)}
          key={name}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              setTogglesValues((prev) => ({
                ...prev,
                [name]: !prev[name],
              }));
            }
          }}
        >
          {createElement(icon as FunctionComponent<any>, {
            className: `h-5 w-5 ${className ?? ""} ${
              getToggleValue(name) ? enabledClassName : disabledClassName
            }`,
          })}
        </Button>
      )
    );

    const renderInput = (field: string) => {
      if (!schema) return null;

      const schemaItem = schema[field];
      const value = values[schemaItem.name];

      if (schemaItem.type === "date") {
        let dayjsValue = dayjs(value);

        // Default the date to today if the value is not a valid date
        if (!dayjsValue.isValid()) {
          dayjsValue = dayjs();
          console.warn(`Invalid date value for field ${schemaItem.name}, defaulting to today (${dayjsValue.format("MM/DD/YYYY")})`);
        }

        return (
          <DatePicker
            onChange={(newValue) => {
              const [start] = newValue;
              setFieldValue(schemaItem.name, start.format("MM/DD/YYYY"));
            }}
            values={[dayjsValue]}
          />
        );
      }

      return (
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          name={schemaItem.name}
          placeholder={schemaItem.placeholder}
          value={values[schemaItem.name] ?? ""}
          id={schemaItem.name}
          error={errors[schemaItem.name] as string}
          data-testid={`input-${schemaItem.name}`}
        />
      );
    };

    return (
      <BaseDialog
        isOpen={isOpen}
        title={title}
        addons={toggleButtons}
        onClose={onDiscard}
        className="gap-4"
      >
        <div className={fieldContainerVariant({ variant })}>
          {schema &&
            Object.keys(schema).map((field) => (
              <div className={fieldVariant({ variant })} key={field}>
                <label
                  className="flex text-sm font-semibold text-foreground mt-2.5"
                  htmlFor={schema[field].name}
                  data-testid={`label-${schema[field].name}`}
                >
                  {schema[field].label}
                </label>
                {renderInput(field)}
              </div>
            ))}
          {children}
        </div>
        <div className="flex-1">
          <div className="flex justify-end items-center gap-2">
            <Button
              variant="ghost"
              onClick={onDiscard}
              data-testid="discard"
              type="button"
              className="min-h-[40px]"
            >
              {actualOptions.discardLabel}
            </Button>
            <Button
              type="submit"
              data-testid="save"
              className="min-w-[100px] min-h-[40px]"
              onClick={() => handleSubmit()}
              disabled={!isValid}
            >
              {isLoading ? <Spinner size={4} /> : actualOptions.saveLabel}
            </Button>
          </div>
        </div>
      </BaseDialog>
    );
  }
);
