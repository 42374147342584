import { FC, PropsWithChildren } from "react";

import { Breadcrumbs, BreadcrumbUnion } from "../../molecules";

interface PageTitleError {
  title?: string;
  subtitle?: string;
}

export interface PageTitleProps {
  breadcrumbs?: BreadcrumbUnion[];
  title: React.ReactNode;
  error?: PageTitleError;
  icon?: React.ReactNode;
}

export const PageTitle: FC<PropsWithChildren<PageTitleProps>> = ({
  title,
  error,
  icon,
  breadcrumbs,
  children,
}) => {
  return (
    <div className="flex flex-col gap-1" data-testid="page-title">
      {breadcrumbs && (
        <div data-testid="breadcrumbs">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </div>
      )}
      <div className="flex">
        <div className="flex items-center">
          {icon}
          <h1 className="text-2xl font-semibold px-2 py-1" data-testid="title">
            {title}
          </h1>
        </div>
        <div className="flex-1 justify-end items-center flex gap-4">
          {children}
        </div>
      </div>
      {error && (
        <div data-testid="error">
          <p className="text-sm flex">
            <span
              className="text-destructive font-semibold"
              data-testid="error-title"
            >
              {error.title}
            </span>
            {error.subtitle && (
              <span className="ml-1" data-testid="error-subtitle">
                {error.subtitle}
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};
