import { ReactNode } from "react";

import { motion } from "framer-motion";

export interface CollapseProps {
  children: ReactNode;
  isOpen: boolean;
  enableOpacity?: boolean;
  duration?: number;
  ease?: string;
  initialHeight?: number;
  className?: string;
}

export const Collapse = ({
  children,
  isOpen,
  enableOpacity = true,
  duration = 0.2,
  ease = "easeOut",
  initialHeight = 0,
  className = "overflow-hidden",
}: CollapseProps) => {
  const opacityIfEnabled = isOpen ? 1 : 0;

  return (
    <motion.div
      initial={{ height: initialHeight }}
      animate={{
        height: isOpen ? "auto" : 0,
        opacity: enableOpacity ? opacityIfEnabled : 1,
      }}
      transition={{ duration, ease }}
      className={className}
    >
      {children}
    </motion.div>
  );
};
