import { useDayPicker } from "react-day-picker";

import dayjs from "dayjs";
import { range } from "lodash";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../comboboxes";

interface CalendarCaptionLabelProps {
  id?: string;
  displayMonth: Date;
}

const selectTriggerStyles = "border-none p-0 text-sm";

export const CalendarCaptionLabel = ({
  id,
  displayMonth
}: CalendarCaptionLabelProps) => {
  const {
    onMonthChange
  } = useDayPicker();

  const handleChangeDate = (date: Date) => {
    onMonthChange?.(date);
  };

  const onChangeMonth = (month: string) => {
    const newMonth = dayjs(displayMonth).month(parseInt(month) - 1).toDate();
    handleChangeDate(newMonth);
  };

  const onChangeYear = (year: string) => {
    const newYear = dayjs(displayMonth).year(parseInt(year)).toDate();
    handleChangeDate(newYear);
  };

  return (
    <div className={"flex items-center gap-2 mx-4 flex-1"} id={id}>
      <Select
        value={dayjs(displayMonth).format("MM")}
        onValueChange={onChangeMonth}
      >
        <SelectTrigger
          icon={null}
          className={selectTriggerStyles}
        >
          <SelectValue placeholder="Month" />
        </SelectTrigger>
        <SelectContent className={"max-h-[180px]"}>
          {Array.from({ length: 12 }).map((_, i) => {
            const month = dayjs().month(i);

            return (
              <SelectItem key={i} value={month.format("MM")}>
                {month.format("MMMM")}
              </SelectItem>
            );
          })}
        </SelectContent>
      </Select>
      <Select
        value={dayjs(displayMonth).format("YYYY")}
        onValueChange={onChangeYear}
      >
        <SelectTrigger
          icon={null}
          className={selectTriggerStyles}
        >
          <SelectValue placeholder="Year" />
        </SelectTrigger>
        <SelectContent className={"max-h-[180px]"}>
          {range(1900, 2100).map((year) => (
            <SelectItem key={year} value={`${year}`}>
              {year}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
