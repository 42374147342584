/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DocumentType,
  KeyValuePair,
  StrategyHyperparams,
} from "@atlas-ui/types";

import API from "../api";

interface ProcessKVPParams {
  documentId: string;
  kvpToExtract: string;
  kvpId: string;
  prompt: string;
  problemTypeId: string;
  hyperparameters?: StrategyHyperparams;
  strategyId?: string;
}

export const processKvp = async (params: ProcessKVPParams) => {
  try {
    const response = await API.post("/admin/process-kvp", params);

    return response.data as { requestId: string };
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error processing key value pair"
    );
  }
};

export const createUserForAdmin = async (params: { email: string }) => {
  try {
    const response = await API.post("/admin/users", params);

    return response.data as { email: string };
  } catch (error) {
    if ((error as any)?.response?.data.data) {
      throw (error as any)?.response?.data.data;
    }
    throw new Error(
      (error as Error)?.message || "Error creating user"
    );
  }
};

export const getKvpsForDocument = async (documentId: string) => {
  try {
    const response = await API.get(`/admin/kvps/${documentId}`);

    return response.data as KeyValuePair[];
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting key value pairs for document"
    );
  }
};

export const getDocumentTypesForAdmin = async () => {
  try {
    const response = await API.get(`/admin/document-types`);

    return response.data as DocumentType[];
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting document types"
    );
  }
};

export const getKvpsForAdmin = async (documentTypeId?: string) => {
  if (!documentTypeId) {
    console.error("No documentTypeId provided");
    return [];
  }

  try {
    const response = await API.get(`/admin/kvps`, {
      params: { documentTypeId },
    });

    return response.data as KeyValuePair[];
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting key value pairs for admin"
    );
  }
};

export const getDashboardSearchResults = async (problemTypeId: string[],
  documentTypeId: string[],
  keyValuePairId: string[],
  groupBy: string[],
  from: string,
  to: string,
) => {
  try {
    const response = await API.get(`/admin/dashboard`, {
      params: {
        problemTypeId: problemTypeId.join(","),
        documentTypeId: documentTypeId.join(","),
        keyValuePairId: keyValuePairId.join(","),
        groupBy: groupBy.join(","),
        from,
        to,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      (error as Error)?.message || "Error getting dashboard search results"
    );
  }
};
