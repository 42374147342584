"use client";

import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from "react";

import { CheckIcon } from "@heroicons/react/24/solid";
import { Indicator, Root } from "@radix-ui/react-checkbox";

import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

import { cn } from "../../../utils";
import { Label } from "../texts";

export type CheckboxSize = "sm" | "lg";

const checkboxVariants = cva(
  "peer shrink-0 border border-muted-foreground ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground",
  {
    variants: {
      size: {
        sm: "h-4 w-4 rounded-sm",
        lg: "h-6 w-6 rounded-lg",
      },
    },
    defaultVariants: {
      size: "sm" as CheckboxSize,
    },
  }
);

const checkboxIndicatorVariants = cva(
  "flex items-center justify-center text-current",
  {
    variants: {
      size: {
        sm: "h-4 w-4",
        lg: "h-6 w-6",
      },
    },
    defaultVariants: {
      size: "sm" as CheckboxSize,
    },
  }
);

export interface CheckboxProps<ID extends string = string>
  extends Omit<ComponentPropsWithoutRef<typeof Root>, "id"> {
  size?: CheckboxSize;
  indicatorClassName?: string;
  labelClassName?: string;
  id?: ID;
  label?: ID extends undefined ? string : ReactNode | undefined;
  testId?: string;
}

export const Checkbox = forwardRef<ElementRef<typeof Root>, CheckboxProps>(
  (
    {
      className,
      size,
      indicatorClassName,
      labelClassName,
      label,
      testId,
      ...props
    },
    ref
  ) => (
    <div className="flex items-start space-x-2">
      <div className={"flex items-center h-[22px]"}>
        <Root
          ref={ref}
          data-testid="checkbox"
          asChild
          className={cn(checkboxVariants({ size }), className)}
          {...props}
        >
          <div data-testid={testId}>
            <Indicator
              className={cn("flex items-center justify-center text-current")}
            >
              <CheckIcon
                data-testid="checkbox-indicator"
                className={cn(
                  checkboxIndicatorVariants({ size }),
                  indicatorClassName
                )}
              />
            </Indicator>
          </div>
        </Root>
      </div>
      {label && (
        <Label
          htmlFor={props.id}
          data-testid="checkbox-label"
          className={twMerge(
            "text-sm font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 mt-[1.25px]",
            labelClassName
          )}
        >
          {label}
        </Label>
      )}
    </div>
  )
);

Checkbox.displayName = Root.displayName;
