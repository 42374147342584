import { forwardRef, useCallback,useEffect, useState } from "react";
import { SelectRangeEventHandler } from "react-day-picker";

import { CalendarDaysIcon } from "@heroicons/react/24/outline";

import dayjs, { Dayjs } from "dayjs";

import { cn } from "../../../utils";
import { Calendar } from "../../molecules/calendars";
import { Button } from "../buttons";
import { Popover, PopoverContent, PopoverTrigger } from "../popovers/Popover";

interface DatepickerProps {
  onChange: (date: Dayjs[]) => void;
  values?: Dayjs[];
  isDateSpan?: boolean;
  className?: string;
}

type DateRange = { from: Date; to: Date } | undefined;

export const DatePicker = forwardRef<HTMLButtonElement, DatepickerProps>(
  ({ values, onChange, isDateSpan = false, className }, ref) => {
    const [internalValues, setInternalValues] = useState<Dayjs[]>(values ?? []);
    const [displayMonth, setDisplayMonth] = useState(() => dayjs());

    useEffect(() => {
      setInternalValues(values ?? []);
      setDisplayMonth(values?.[0] ? dayjs(values[0]) : dayjs());
    }, [values]);

    const formatValue = useCallback(() => {
      const [start, end] = internalValues;
      if (!start?.isValid()) {
        return 'Select a date';
      }

      const startFormat = start.format("MMM DD, YYYY");
      return isDateSpan && end
        ? `${startFormat} - ${end.format("MMM DD, YYYY")}`
        : startFormat;
    }, [internalValues, isDateSpan]);

    const dateRange: Date | DateRange = isDateSpan
      ? { from: internalValues[0]?.toDate(), to: internalValues[1]?.toDate() }
      : internalValues[0]?.toDate();

    const handleDateChange = useCallback((date: Date | DateRange) => {
      if (!date) return;

      const newValues = 'from' in date
        ? [dayjs(date.from), dayjs(date.to)]
        : [dayjs(date)];

      onChange(newValues);
      setInternalValues(newValues);
    }, [onChange]);

    const handleMonthChange = useCallback((date: Date) => {
      setDisplayMonth(dayjs(date));
    }, []);

    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            ref={ref}
            className={cn(
              "h-[32px] px-3 py-2 flex items-center justify-start gap-[10px]",
              className
            )}
          >
            <CalendarDaysIcon className="w-4 h-4 text-foreground" />
            <span className="text-xs font-medium text-foreground leading-[21px]">
              {formatValue()}
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex-col w-auto relative mr-8 min-h-[360px]">
          <div className="flex gap-4">
            {(isDateSpan ? [0, 1] : [0]).map((offset) => (
              <Calendar
                key={offset}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mode={(isDateSpan ? "range" : "single") as any}
                month={displayMonth.add(offset, "month").toDate()}
                selected={dateRange}
                onMonthChange={handleMonthChange}
                onSelect={handleDateChange as SelectRangeEventHandler}
              />
            ))}
          </div>
        </PopoverContent>
      </Popover>
    );
  }
);

DatePicker.displayName = "DatePicker";
